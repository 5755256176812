/** @jsxRuntime classic */
/** @jsx h */
import {
  AutocompletePlugin,
  getAlgoliaResults,
} from '@algolia/autocomplete-js';
import { SearchResponse } from '@algolia/client-search';
import { h } from 'preact';

import { ALGOLIA_CITIES_INDEX_NAME, LOCALE, applicationTranslation, DEBOUNCE_TIME } from '../constants';
import { searchClient } from '../searchClient';
import { QuickAccessHit } from '../types';
import { cx } from '../utils';
import { debouncePromise } from '../utils/debounce';
import { RequesterDescription } from '@algolia/autocomplete-shared/dist/esm/preset-algolia/createRequester';

const debounced = debouncePromise<RequesterDescription<QuickAccessHit>>((a) => getAlgoliaResults(a), DEBOUNCE_TIME);

export const quickAccessPlugin: AutocompletePlugin<QuickAccessHit, {}> = {
  getSources({ query }) {
    if (query) {
      return [];
    }

    return [
      {
        sourceId: 'quickAccessPlugin',
        getItems() {
          return debounced({
            searchClient,
            queries: [
              {
                indexName: ALGOLIA_CITIES_INDEX_NAME,
                query,
                params: {
                  hitsPerPage: 0,
                  ruleContexts: ['quickAccess'],
                },
              },
            ],
            transformResponse({ results }) {
              return (
                (results as SearchResponse[])?.[0].userData?.[0]?.items || []
              );
            },
          });
        },
        templates: {
          header({ Fragment }) {
            return (
              <Fragment>
                <span className="aa-SourceHeaderTitle">{applicationTranslation[LOCALE]['app.ui.quick.access']}</span>
                <span className="text-main-color float-right"><a href={`/${applicationTranslation[LOCALE]['app.link.activities']}`}>{applicationTranslation[LOCALE]['app.ui.all.activities']}</a></span>
              </Fragment>
            );
          },
          item({ item }) {
            return <QuickAccessItem hit={item} />;
          },
        },
      },
    ];
  },
};

type QuickAccessItemProps = {
  hit: QuickAccessHit;
};

function QuickAccessItem({ hit }: QuickAccessItemProps) {
  return (
    <a
      href={`/${applicationTranslation[LOCALE]['app.link.activities']}/${hit.slug}`}
      className={cx(
        'aa-ItemLink aa-QuickAccessItem',
        `aa-QuickAccessItem--sales-banner`
      )}>
      <div className="aa-ItemContent">
        {hit.image && (
          <div className="aa-ItemPicture">
            <img src={`${hit.image}`} alt={hit.name} />
          </div>
        )}

        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            {hit.name}
          </div>
        </div>
      </div>
    </a>
  );
}
