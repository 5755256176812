import algoliasearch from 'algoliasearch/lite';

const appData = document.getElementById("data-js") ?? document.createElement("div");
const appID = appData.dataset.algoliaAppId ?? "";
const apiKey = appData.dataset.algoliaReadonlyApiKey ?? "";

export const searchClient = algoliasearch(
  appID,
  apiKey
);
