/** @jsxRuntime classic */
/** @jsx h */
import {
  AutocompleteComponents,
  AutocompletePlugin,
  getAlgoliaResults,
} from '@algolia/autocomplete-js';
import { h } from 'preact';

import { PinIcon } from '../components';
import { ALGOLIA_CITIES_INDEX_NAME, LOCALE, applicationTranslation, DEBOUNCE_TIME } from '../constants';
import { searchClient } from '../searchClient';
import { CityHit } from '../types';
import { debouncePromise } from '../utils/debounce';
import { RequesterDescription } from '@algolia/autocomplete-shared/dist/esm/preset-algolia/createRequester';

const debounced = debouncePromise<RequesterDescription<CityHit>>((a) => getAlgoliaResults(a), DEBOUNCE_TIME);

export const citiesPlugin: AutocompletePlugin<CityHit, {}> = {
  getSources({ query }) {
    if (!query) {
      return [];
    }

    return [
      {
        sourceId: 'citiesPlugin',
        getItems() {
          return debounced({
            searchClient,
            queries: [
              {
                indexName: ALGOLIA_CITIES_INDEX_NAME,
                query,
                params: {
                  hitsPerPage: 3,
                },
              },
            ],
          });
        },
        getItemInputValue({ item }) {
          return item.name;
        },
        templates: {
          item({ item, components }) {
            return <CityItem hit={item} components={components} />;
          },
        },
      },
    ];
  },
};

type CityItemProps = {
  hit: CityHit;
  components: AutocompleteComponents;
};

function CityItem({ hit, components }: CityItemProps) {
  return (
    <div className="aa-ItemWrapper aa-CityItem">
      <div className="aa-ItemContent">
        <div className="aa-ItemIcon aa-ItemIcon--noBorder">
          <PinIcon />
        </div>
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <a href={`/${applicationTranslation[LOCALE]['app.link.activities']}/${hit.slug}`} className="aa-TextLink">
              <components.Highlight
                hit={hit}
                attribute="name"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
