/** @jsxRuntime classic */
/** @jsx h */
import {
  AutocompleteComponents,
  AutocompletePlugin,
  getAlgoliaResults,
} from "@algolia/autocomplete-js";
import { SearchResponse } from "@algolia/client-search";
import { h } from "preact";
import { useState } from "preact/hooks";

import { RequesterDescription } from "@algolia/autocomplete-shared/dist/esm/preset-algolia/createRequester";
import { StarIcon } from "../components";
import {
  ALGOLIA_PRODUCTS_INDEX_NAME,
  DEBOUNCE_TIME,
  LOCALE,
  applicationTranslation,
} from "../constants";
import { searchClient } from "../searchClient";
import { ProductHit } from "../types";
import { cx } from "../utils";
import { debouncePromise } from "../utils/debounce";

const debounced = debouncePromise<RequesterDescription<ProductHit>>(
  (a) => getAlgoliaResults(a),
  DEBOUNCE_TIME
);

export const productsPlugin: AutocompletePlugin<ProductHit, {}> = {
  getSources({ query }) {
    if (!query) {
      return [];
    }

    return [
      {
        sourceId: "productsPlugin",
        getItems({ setContext }) {
          return debounced({
            searchClient,
            queries: [
              {
                indexName: ALGOLIA_PRODUCTS_INDEX_NAME,
                query,
                params: {
                  hitsPerPage: 4,
                },
              },
            ],
            transformResponse({ hits, results }) {
              setContext({
                nbProducts: (results[0] as SearchResponse<ProductHit>).nbHits,
              });

              return hits;
            },
          });
        },
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          header({ state, Fragment }) {
            return (
              <Fragment>
                <div className="aa-SourceHeaderTitle">
                  {applicationTranslation[LOCALE]["app.ui.productsfor"]}
                </div>
                <span className="text-main-color float-right">
                  <a
                    href={`/${applicationTranslation[LOCALE]["app.link.activities"]}`}
                  >
                    {applicationTranslation[LOCALE]["app.ui.all.activities"]}
                  </a>
                </span>
                {/* <div className="aa-SourceHeaderLine" /> */}
              </Fragment>
            );
          },
          item({ item, components }) {
            return <ProductItem hit={item} components={components} />;
          },
        },
      },
    ];
  },
};

function formatPrice(value: number, currency: string) {
  return value.toLocaleString("fr-FR", { style: "currency", currency });
}

type ProductItemProps = {
  hit: ProductHit;
  components: AutocompleteComponents;
};

function ProductItem({ hit, components }: ProductItemProps) {
  const [loaded, setLoaded] = useState(false);

  return (
    <a
      href={"/" + hit.slugCity + "/" + hit.slug + "+" + hit.providerSlug}
      target="_blank"
      rel="noreferrer noopener"
      className="aa-ItemLink aa-ProductItem"
    >
      <div className="aa-ItemContent">
        <div
          className={cx("aa-ItemPicture", loaded && "aa-ItemPicture--loaded")}
        >
          {hit.images && hit.images.length > 0 && (
            <img
              src={hit.images[0]}
              alt={hit.name}
              onLoad={() => setLoaded(true)}
            />
          )}
        </div>

        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentRating">
            <ul>
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <li key={index}>
                    <div
                      className={cx(
                        "aa-ItemIcon aa-ItemIcon--noBorder aa-StarIcon",
                        !hit.reviews ||
                          (index >= hit.reviews.rating && "aa-StarIcon--muted")
                      )}
                    >
                      <StarIcon />
                    </div>
                  </li>
                ))}
            </ul>
            {hit.reviews && hit.reviews.count && (
              <span className="aa-ItemContentRatingReviews">
                ({hit.reviews.count})
              </span>
            )}
            {hit.city && (
              <div className="aa-ItemContentBrand">
                <components.Highlight hit={hit} attribute="city" />
              </div>
            )}
          </div>
          <div>
            <div className="aa-ItemContentTitleWrapper">
              <div className="aa-ItemContentTitle">
                <components.Highlight hit={hit} attribute="name" />
              </div>
            </div>
          </div>
          <div>
            {hit.price && (
              <div className="aa-ItemContentPrice">
                <div className="aa-ItemContentPriceCurrent">
                  {formatPrice(hit.price.value, hit.price.currency)}
                </div>
                {hit.price.on_sales && (
                  <div className="aa-ItemContentPriceDiscounted">
                    {formatPrice(
                      hit.price.discounted_value,
                      hit.price.currency
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
}
