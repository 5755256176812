const debouncePromise = function debouncePromise<T>(fn, time) {
    let timerId ;

    return function debounced(...args) {
        if (timerId) {
        clearTimeout(timerId);
        }

        return new Promise<T>((resolve) => {
        timerId = setTimeout(() => resolve(fn(...args)), time);
        });
    };
}

export { debouncePromise };