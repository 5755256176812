/** @jsxRuntime classic */
/** @jsx h */
import {
    AutocompleteComponents,
    AutocompletePlugin,
    getAlgoliaResults,
} from "@algolia/autocomplete-js";
import { h } from "preact";

import { RequesterDescription } from "@algolia/autocomplete-shared/dist/esm/preset-algolia/createRequester";
import { GridIcon } from "../components";
import {
    ALGOLIA_CATEGORIES_INDEX_NAME,
    applicationTranslation,
    DEBOUNCE_TIME,
    LOCALE,
} from "../constants";
import { searchClient } from "../searchClient";
import { CategoryHit } from "../types";
import { debouncePromise } from "../utils/debounce";

const debounced = debouncePromise<RequesterDescription<CategoryHit>>(
    (a) => getAlgoliaResults(a),
    DEBOUNCE_TIME
);

export const categoriesPlugin: AutocompletePlugin<CategoryHit, {}> = {
    getSources({ query }) {
        if (!query) {
            return [];
        }

        return [
            {
                sourceId: "categoriesPlugin",
                getItems() {
                    return debounced({
                        searchClient,
                        queries: [
                            {
                                indexName: ALGOLIA_CATEGORIES_INDEX_NAME,
                                query,
                                params: {
                                    hitsPerPage: 3,
                                },
                            },
                        ],
                    });
                },
                getItemInputValue({ item }) {
                    return item.name;
                },
                templates: {
                    item({ item, components }) {
                        return (
                            <CategoryItem hit={item} components={components} />
                        );
                    },
                },
            },
        ];
    },
};

type CategoryItemProps = {
    hit: CategoryHit;
    components: AutocompleteComponents;
};

function CategoryItem({ hit, components }: CategoryItemProps) {
    let linkType =
        hit.type === "HOSTING" ? "app.link.hostings" : "app.link.activities";
    return (
        <div className="aa-ItemWrapper aa-CategoryItem">
            <div className="aa-ItemContent">
                <div className="aa-ItemIcon aa-ItemIcon--noBorder">
                    <GridIcon />
                </div>
                <div className="aa-ItemContentBody">
                    <div className="aa-ItemContentTitle">
                        <a
                            href={`/${applicationTranslation[LOCALE][linkType]}/${hit.slug}`}
                            className="aa-TextLink"
                        >
                            <components.Highlight hit={hit} attribute="name" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
