/** @jsxRuntime classic */
/** @jsx h */
import { autocomplete } from "@algolia/autocomplete-js";
import { h, render } from "preact";
import { pipe } from "ramda";

import { applicationTranslation, LOCALE } from "./constants";
import { createFillWith, uniqBy } from "./functions";
import { categoriesPlugin } from "./plugins/categoriesPlugin";
import { citiesPlugin } from "./plugins/citiesPlugin";
import { popularCategoriesPlugin } from "./plugins/popularCategoriesPlugin";
import { popularPlugin } from "./plugins/popularPlugin";
import { productsPlugin } from "./plugins/productsPlugin";
import { querySuggestionsPlugin } from "./plugins/querySuggestionsPlugin";
import { quickAccessPlugin } from "./plugins/quickAccessPlugin";
import { recentSearchesPlugin } from "./plugins/recentSearchesPlugin";
import { cx, hasSourceActiveItem, isDetached } from "./utils";

import "../theme.css";

const removeDuplicates = uniqBy(({ source, item }) => {
    const sourceIds = ["recentSearchesPlugin", "querySuggestionsPlugin"];
    if (sourceIds.indexOf(source.sourceId) === -1) {
        return item;
    }

    return source.sourceId === "querySuggestionsPlugin"
        ? item.query
        : item.label;
});

const fillWith = createFillWith({
    mainSourceId: "querySuggestionsPlugin",
    limit: isDetached() ? 6 : 10,
});

const combine = pipe(removeDuplicates, fillWith);

let placeholder = applicationTranslation[LOCALE]["app.ui.search.placeholder"];

if (window.matchMedia("(max-width: 680px)").matches) {
    placeholder =
        applicationTranslation[LOCALE]["app.ui.search.mobile.placeholder"];
}

autocomplete({
    container: "#autocomplete",
    placeholder: placeholder,
    // panelContainer: '#blackPanelContainer',
    translations: {
        detachedCancelButtonText:
            applicationTranslation[LOCALE]["app.ui.search.cancel"],
    },
    panelPlacement: "full-width",
    autoFocus: false,
    openOnFocus: true,
    insights: false,
    plugins: [
        recentSearchesPlugin,
        querySuggestionsPlugin,
        categoriesPlugin,
        citiesPlugin,
        productsPlugin,
        popularPlugin,
        quickAccessPlugin,
        popularCategoriesPlugin,
    ],
    reshape({ sourcesBySourceId, sources, state }) {
        const {
            recentSearchesPlugin: recentSearches,
            querySuggestionsPlugin: querySuggestions,
            categoriesPlugin: categories,
            citiesPlugin: cities,
            faqPlugin: faq,
            popularPlugin: popular,
            popularCategoriesPlugin: popularCategories,
            ...rest
        } = sourcesBySourceId;

        const sourceIdsToExclude = ["popularPlugin", "popularCategoriesPlugin"];
        const shouldDisplayPopularCategories = sources.every((source) => {
            if (sourceIdsToExclude.indexOf(source.sourceId) !== -1) {
                return true;
            }
            return source.getItems().length === 0;
        });

        return [
            combine(recentSearches, querySuggestions, categories, cities, faq),
            [
                !state.query && popular,
                ...Object.values(rest),
                shouldDisplayPopularCategories && popularCategories,
            ].filter(Boolean),
        ];
    },
    render({ elements, state, Fragment }, root) {
        const {
            recentSearchesPlugin: recentSearches,
            querySuggestionsPlugin: querySuggestions,
            categoriesPlugin: categories,
            citiesPlugin: cities,
            brandsPlugin: brands,
            productsPlugin: products,
            popularPlugin: popular,
            quickAccessPlugin: quickAccess,
            popularCategoriesPlugin: popularCategories,
        } = elements;

        const sourceIdsToExclude = ["popularPlugin", "popularCategoriesPlugin"];
        const hasResults =
            state.collections
                .filter(
                    ({ source }) =>
                        sourceIdsToExclude.indexOf(source.sourceId) === -1
                )
                .reduce((prev, curr) => prev + curr.items.length, 0) > 0;

        render(
            <div className="aa-LayoutAndBlack">
                <div className="aa-PanelLayout aa-Panel--scrollable">
                    <div className="aa-PanelSections page-wrapper">
                        <div className="aa-PanelSection--left">
                            {!hasResults && (
                                <div className="aa-NoResultsQuery">
                                    {
                                        applicationTranslation[LOCALE][
                                            "app.ui.noresult"
                                        ]
                                    }{" "}
                                    "{state.query}".
                                </div>
                            )}
                            {hasResults ? (
                                (!state.query && recentSearches && (
                                    <Fragment>
                                        <div className="aa-SourceHeader">
                                            <span className="aa-SourceHeaderTitle">
                                                {
                                                    applicationTranslation[
                                                        LOCALE
                                                    ]["app.ui.recentsearches"]
                                                }
                                            </span>
                                            {/* <div className="aa-SourceHeaderLine" /> */}
                                        </div>
                                        {recentSearches}
                                    </Fragment>
                                )) ||
                                (state.query && (
                                    <Fragment>
                                        {(recentSearches ||
                                            querySuggestions) && (
                                            <div className="aa-SourceHeader">
                                                <span className="aa-SourceHeaderTitle">
                                                    {
                                                        applicationTranslation[
                                                            LOCALE
                                                        ]["app.ui.suggestions"]
                                                    }
                                                </span>
                                            </div>
                                        )}
                                        {(recentSearches ||
                                            querySuggestions) && (
                                            <div className="aa-PanelSectionSources">
                                                {recentSearches}
                                                {querySuggestions}
                                            </div>
                                        )}
                                        {categories && (
                                            <div className="aa-SourceHeader">
                                                <span className="aa-SourceHeaderTitle">
                                                    {
                                                        applicationTranslation[
                                                            LOCALE
                                                        ]["app.ui.categories"]
                                                    }
                                                </span>
                                            </div>
                                        )}
                                        {categories && (
                                            <div className="aa-PanelSectionSources">
                                                {categories}
                                            </div>
                                        )}
                                        {cities && (
                                            <div className="aa-SourceHeader">
                                                <span className="aa-SourceHeaderTitle">
                                                    {
                                                        applicationTranslation[
                                                            LOCALE
                                                        ]["app.ui.cities"]
                                                    }
                                                </span>
                                            </div>
                                        )}
                                        {cities && (
                                            <div className="aa-PanelSectionSources">
                                                {cities}
                                            </div>
                                        )}
                                        {brands && (
                                            <div className="aa-SourceHeader">
                                                <span className="aa-SourceHeaderTitle">
                                                    {
                                                        applicationTranslation[
                                                            LOCALE
                                                        ]["app.ui.sites"]
                                                    }
                                                </span>
                                            </div>
                                        )}
                                        {brands && (
                                            <div className="aa-PanelSectionSources">
                                                {brands}
                                            </div>
                                        )}
                                    </Fragment>
                                ))
                            ) : (
                                <div className="aa-NoResultsAdvices">
                                    <ul className="aa-NoResultsAdvicesList">
                                        <li>
                                            {
                                                applicationTranslation[LOCALE][
                                                    "app.ui.noresult.advices.1"
                                                ]
                                            }
                                        </li>
                                        <li>
                                            {
                                                applicationTranslation[LOCALE][
                                                    "app.ui.noresult.advices.2"
                                                ]
                                            }
                                        </li>
                                        <li>
                                            {
                                                applicationTranslation[LOCALE][
                                                    "app.ui.noresult.advices.3"
                                                ]
                                            }
                                        </li>
                                        <li>
                                            {
                                                applicationTranslation[LOCALE][
                                                    "app.ui.noresult.advices.4"
                                                ]
                                            }
                                        </li>
                                    </ul>
                                </div>
                            )}

                            {!state.query && (
                                <div className="aa-PanelSection--popular">
                                    {popular}
                                </div>
                            )}
                        </div>
                        <div className="aa-PanelSection--right">
                            {products && (
                                <div className="aa-PanelSection--products">
                                    <div className="aa-PanelSectionSource">
                                        {products}
                                    </div>
                                </div>
                            )}

                            {quickAccess && (
                                <div
                                    className={cx(
                                        "aa-PanelSection--quickAccess aa-PanelSection--zoomable",
                                        hasSourceActiveItem(
                                            "quickAccessPlugin",
                                            state
                                        ) && "aa-PanelSection--active"
                                    )}
                                >
                                    {quickAccess}
                                </div>
                            )}

                            {!hasResults && (
                                <div
                                    className={cx(
                                        "aa-PanelSection--quickAccess aa-PanelSection--zoomable",
                                        hasSourceActiveItem(
                                            "quickAccessPlugin",
                                            state
                                        ) && "aa-PanelSection--active"
                                    )}
                                >
                                    {popularCategories}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div></div>
                <div
                    onClick={() => {
                        state.isOpen = false;
                        root.remove();
                    }}
                    className="aa-black"
                ></div>
            </div>,
            root
        );
    },
    onSubmit({ state }) {
        let collections = state.collections;

        let result = getFirstValueOfCollection(collections, "categoriesPlugin");

        if (result === null) {
            result = getFirstValueOfCollection(collections, "citiesPlugin");
        }

        if (result !== null) {
            let linkType =
                result.hasOwnProperty("type") && result.type === "HOSTING"
                    ? "app.link.hostings"
                    : "app.link.activities";
            window.location.href = `/${applicationTranslation[LOCALE][linkType]}/${result.slug}`;
        } else {
            console.log(state);
        }

        if (result === null) {
            window.location.href = `/${applicationTranslation[LOCALE]["app.link.activities"]}?query=${state.query}`;
        }
    },
});

var getFirstValueOfCollection = function (collections, type) {
    for (const collection of collections) {
        if (
            collection.source.sourceId === type &&
            collection.items.length > 0
        ) {
            return collection.items[0];
        }
    }

    return null;
};
